import React, {useState, useEffect} from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom'
import {SettingLabel, SettingsRadioQuestion} from './ballots/generic_ballot_components';
import './navbar.css'
const links = require('./constants/links.json')

export const NavBarMenu = props => {
  const location = useLocation()
  const history = useHistory()
  const [activeSetting, setActiveSetting] = useState('unset')

  useEffect(() => {
    if ((props.showSettings === false) && (activeSetting !== 'unset')) {
      setActiveSetting('unset')
    }
  }, [])

  const openSetting = id => (id === activeSetting) ? setActiveSetting('unset') : setActiveSetting(id)

  if ((props.language === 'en') && (location.pathname.startsWith('/fr'))) {
    props.updateAppState({language: 'fr', mapKey: 23})
  } else if ((props.language === 'fr') && !(location.pathname.startsWith('/fr'))) {
    props.updateAppState({language: 'en', mapKey: 23})
  }

  const setFunFact = val => props.updateAppState({funFact: (val === 'true')})
  const setIsSubscribed = val => {
    if (!Boolean(props.userID)) {
      if (val === 'true') {
        alert(props.language === 'en' ? `Please log in to be able to sign up for 
        the newsletter.` : `Connectez-vous svp afin de pouvoir vous abonner à la 
        newsletter.`)
      }
    } else {
      props.updateAppState({isSubscribed: (val === 'true')})
    }
  }

  const setLanguage = language => {
    if (language === props.language) {
      return
    } else if (language === 'en') {
      history.push(location.pathname.slice(3))
    } else {  // new language is a non-English language
      history.push('/' + language + location.pathname)
    }
    
    props.updateAppState({language})
  }
  const levels = ["🌰", "🌱", "🌿", "🌼", "🌻", "🎍", "🌳"]
  const _links = links[props.language]

  return (
    <div className='navbarMenu' id='navbarMenu'>
      <div className='navbarLinks'>
        <Link id='profileThumbnail' to={_links.profileUrl}>
          {`${_links.level} ${props.level + 1}`}
          <br/>
          <div className='levelBadge'>{levels[props.level]}</div>
        </Link>
        <ul>
          <li> <Link id='menuHomeLink' to={_links.homeUrl}>{_links.homeText}</Link> </li>
          <li> <Link id='menuProfileLink' to={_links.profileUrl}>{_links.profileText}</Link> </li>
          <li> <Link id='menuAboutLink' to={_links.aboutUrl}>{_links.aboutText}</Link> </li>
          <li> <Link id='menuBlogLink' to={_links.blogUrl}>{_links.blogText}</Link> </li>
          <li> <Link id='menuPressLink' to={_links.pressUrl}>{_links.pressText}</Link> </li>
          <li> <Link id='menuLoginLink' to={_links.loginUrl}>{_links.loginText}</Link> </li>
          <li> <Link id='menuContactLink' to={_links.contactUrl}>{_links.contactText}</Link> </li>
        </ul>
      </div>
      <div id='settingsArea'>
        <button id='settingsBtn'
          onClick={e => {props.updateAppState({showSettings: !props.showSettings})}}>
          {`${props.language === 'en' ? 'Settings' : 'Réglages'} 
          ${props.showSettings ? '˅' : '>'}`}
        </button>
        <div className={`settingsMenu display-${props.showSettings}`}>
          <SettingLabel prompt={props.language === 'en' ? 'Theme: 🎨' : 
            'Palette de couleurs 🎨'} openSetting={openSetting} qName='theme'
            activeSetting={activeSetting} tipText={props.language === 'en' ?
            "Pick a light or dark color scheme." :
            "Choisissez une palette de couleurs claires ou sombres."}
          />
          <SettingsRadioQuestion getter={props.theme} setter={props.setTheme} 
            choices={props.language === 'en' ? 
            ['Light ☀️', 'Dark 🌙'] : ['Claire ☀️', 'Sombre 🌙']}
            values={['light', 'dark']} id='themeSetting' qName='theme'
            accordionGetter={activeSetting}
          />
          <SettingLabel openSetting={openSetting} prompt={props.language === 'en' ?
            'Monthly Newsletter: 📮' : 'Infolettre Mensuelle: 📮'}
            qName='newsletter' activeSetting={activeSetting}
            tipText={props.language === 'en' ?
            `Subscribe to our monthly newsletter for all our 
            stories and updates that are hard to fit on the map.` :
            `S'abonner à notre infolettre mensuelle où on partage 
            des histoires et mises-à-jour qu'on n'arrive pas à montrer sur la 
            carte.`}
          />
          <SettingsRadioQuestion getter={props.isSubscribed}
            setter={setIsSubscribed} choices={props.language === 'en' ? 
            ['Subscribe', 'Unsubscribe'] : ["S'abonner", 'Se désabonner']}
            values={[true, false]} id='newsletterSetting' qName='newsletter'
            accordionGetter={activeSetting}
          />
          <SettingLabel activeSetting={activeSetting} prompt={props.language === 'en' ?
            'Daily Fun Fact: 🗒️' : `Anecdote du jour: 🗒️`} qName='funFact'
            openSetting={openSetting} tipText={props.language === 'en' ?
            `Choose whether to show our Fun Fact of the Day! Only available if you have submitted reviews.`
            : `Montrer ou cacher l'Anecdote du jour! Seulement disponible si vous avez soumis des évaluations.`}
          />
          <SettingsRadioQuestion qName='funFact' getter={props.funFact}
            setter={setFunFact} values={[true, false]} id='funFactSetting'
            choices={props.language === 'en' ? ['Show', 'Hide'] :
            ['Montrer', 'Cacher']} accordionGetter={activeSetting}
          />
          <SettingLabel qName='language' prompt={props.language === 'en' ?
            'Language: 🗣️' : 'Langue: 🗣️'} openSetting={openSetting}
            activeSetting={activeSetting} tipText={props.language === 'en' ?
            "Choose your language." : "Choisir la langue."}
          />
          <SettingsRadioQuestion qName='language' id='languageSetting'
            getter={props.language} setter={setLanguage} values={['en', 'fr']}
            choices={['English', 'Français']} accordionGetter={activeSetting}
          />
          <SettingLabel qName='iconDensity' prompt={props.language === 'en' ? 
            'Icon overlap: 📍 ' : `Chevauchement d'icônes: 📍 `}
            openSetting={openSetting} activeSetting={activeSetting}
            tipText={props.language === 'en' ? 
            `Control at which level of zoom map icons are still 
            allowed to overlap. Dense/sparse settings are often
            preferred for suburbs/cities respectively.` :
            `Contrôler jusqu'à quel niveau de zoom les chevauchements 
            d'icônes sur la carte sont permises. Réglages dense/épars
            sont souvent préférés pour les endroits ruraux/urbans 
            respectivement.`}
          />
          <div className={`sliderBox settingQuestionCard 
            display-${'iconDensity' === activeSetting}`}
          >
            <span>Value:</span>
            <div className='justifiedLabelsBox'>
              <p>Dense</p><p>•</p><p>•</p><p>•</p><p>•</p>
              <p>{props.language === 'en' ? 'Sparse' : 'Épars'}</p>
            </div>
            <input className="settingSlider" id="overlapSlider" min="8"
              max="16" type="range" value={String(props.overlapPoint)}
              onChange={props.handleOverlapPoint}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
