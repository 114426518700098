import React from 'react'
import {Link} from 'react-router-dom'


export const JustLogo = (props) => {
  return(
    <div className='logo boxShadowFocus'>{props.text === undefined ? 'EcoRate' : props.text}
      <div className='mugContainer' id='logoMug'>
        <div className='mugBody' id='logoMugBody' role='img'>
          <div className='heart' id='logoHeart'></div>
          <div className='flac' id='logoFlac'></div>
          <div className='handle' id='logoHandle'></div>
        </div>
      </div>
    </div>
)
}

export const Logo = (props) => {

  return(
    <Link to={props.language === 'fr' ? '/fr/' : '/'} className='logo boxShadowFocus'>
      <JustLogo text={props.text}/>
    </Link>
  )
}
