'use strict';
import React, {Suspense, lazy} from 'react'
import ReactDOM from 'react-dom'
import {Sidebar} from './sidebar'
import {NavBarMenu} from './navbar'
import {JustLogo} from './logo'
import {BrowserRouter as Router, Redirect, Switch, Route, Link} from 'react-router-dom'
import './sidebarStyle.css'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faUser, faPenToSquare } from '@fortawesome/free-regular-svg-icons'
const {Themes, MapStyles, Settings} = require("./constants/themes.json")
const About = React.lazy(() => import('./about'))
const AboutSidebar = React.lazy(() => import('./aboutSidebar'))
const AppLink = lazy(() => import('./appLink'))
const Blog = lazy(() => import('./blog'))
const BlogPost = lazy(() => import('./blog_posts/blogPost'))
const Confirm = lazy(() => import('./confirmUser'))
const Contact = lazy(() => import('./contact'))
const LoginRegister = lazy(() => import('./login'))
const Map = lazy(() => import('./map'))
const Press = lazy(() => import('./press'))
const SocialMediaIconLinks = lazy(() => import('./socialMediaIconLinks'))
const UserArea = lazy(() => import('./userArea'))

const links = require('./constants/links.json')
const url = process.env.NODE_ENV === 'production' ? 'https://ecorate.eco/' : 'http://localhost:5000/'
const _ = require("lodash")


class Application extends React.Component {
  constructor(props) {
    super()
    const ls_fF = localStorage.getItem('EcoRate_funFact')
    this.state = {
      isRemembered: true,
      isReviewer: localStorage.getItem('EcoRate_isReviewer') === 'true',
      isSubscribed: false,
      authToken: localStorage.getItem('EcoRate_authToken') || undefined,
      userID: localStorage.getItem('EcoRate_userID') || undefined,
      level: Number(localStorage.getItem('EcoRate_level')) || 0,
      theme: localStorage.getItem('EcoRate_theme') || 'light',
      funFact: typeof(ls_fF) === 'string' ? ls_fF === 'true' : true,
      mapStyle: MapStyles[Themes[localStorage.getItem('EcoRate_theme') || 'light']],
      language: localStorage.getItem('EcoRate_language') || 'en',
      overlapPoint: Number(localStorage.getItem('EcoRate_overlapPoint')) || 15,
      userRemoteSettings: undefined,
      platform: Capacitor.getPlatform() || 'ios',
      mapKey: 14,
      showSettings: false,
      lng: undefined,
      lat: undefined,
      bearing: 0,
      zoom: 11,
      venue: {
        name: '',
        address: '',
        longitude: undefined,
        latitude: undefined,
        foursquareID: '',
        neighborhood: '',
        postalCode: '',
        country: '',
        rating: '',
        cupShare: false,
        showBallot: false,
        adding: false
      },
      map: undefined,
      mapFilter: '',
      reviewHistory: []
    };

    this.updateRemoteSettings = this.updateRemoteSettings.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.toggleNavHelper = this.toggleNavHelper.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.toggleBallot = this.toggleBallot.bind(this);
    this.passToken = this.passToken.bind(this);
    this.handleOverlapPoint = this.handleOverlapPoint.bind(this);
    this.removeChildClassMembers = this.removeChildClassMembers.bind(this);
    this.resizeMap = this.resizeMap.bind(this);
    this.getReviewHistory = this.getReviewHistory.bind(this);
    this.logOut = this.logOut.bind(this);

    this.routes = [
      { path: '/',
        exact: true,
        sidebar: () => <Sidebar venue={this.state.venue} authToken={this.state.authToken}
          userID={this.state.userID} toggleBallot={this.toggleBallot}
          updateAppState={this.updateAppState} goToAddress={this.goToAddress}
          isReviewer={this.state.isReviewer} funFact={this.state.funFact}
          language={this.state.language} mapKey={this.state.mapKey}
          platform={this.state.platform} removeChildClassMembers={this.removeChildClassMembers}
          resizeMap={this.resizeMap} level={this.state.level} theme={this.state.theme}/>,
        main: () => <Suspense fallback='Loading...'>
          <Map updateAppState={this.updateAppState} lng={this.state.lng} mapStyle={this.state.mapStyle}
            lat={this.state.lat} toggleBallot={this.toggleBallot} authToken={this.state.authToken}
            bearing={this.state.bearing} zoom={this.state.zoom} theme={this.state.theme}
            language={this.state.language} key={this.state.mapKey} mapFilter= {this.state.mapFilter}
            overlapPoint={this.state.overlapPoint} userID={this.state.userID}
            platform={this.state.platform} removeChildClassMembers={this.removeChildClassMembers}
            reviewHistory={this.state.reviewHistory} getReviewHistory={this.getReviewHistory}/>
        </Suspense>
      },
      { path: '/about',
        sidebar: () => <Suspense fallback='Loading...'><AboutSidebar language={this.state.language}/></Suspense>,
        main: () => <Suspense fallback='Loading...'><About language={this.state.language}/></Suspense>
      },
      { path: '/login',
        sidebar: () => <Suspense fallback='Loading...'><SocialMediaIconLinks/></Suspense>,
        main: () => <Suspense fallback='Loading...'>
          <LoginRegister authToken={this.state.authToken}
            setTheme={this.setTheme} updateAppState={this.updateAppState}
            isRemembered={this.state.isRemembered} language={this.state.language}
            theme={this.state.theme} overlapPoint={this.state.overlapPoint}
            funFact={this.state.funFact} platform={this.state.platform}/>
        </Suspense>
      },
      { path: '/contact',
        sidebar: () => <Suspense fallback='Loading...'><SocialMediaIconLinks/></Suspense>,
        main: () => <Suspense fallback='Loading...'>
          <Contact authToken={this.state.authToken} userID={this.state.userID}
              platform={this.state.platform} language={this.state.language} />
        </Suspense>
      },
      { path: '/user',
        sidebar: () => <></>,
        main: () => <Suspense fallback='Loading...'>
          <UserArea language={this.state.language} userID={this.state.userID}
            level={this.state.level} logOut={this.logOut} reviewHistory={this.state.reviewHistory}
            getReviewHistory={this.getReviewHistory}/>
        </Suspense>
      },
      { path: '/press',
        sidebar: () => <></>,
        main: () => <Suspense fallback='Loading...'>
          <Press language={this.state.language} platform={this.state.platform} theme={this.state.theme}/>
        </Suspense>
      },
      { path: '/blog',
        exact: true,
        sidebar: () => <Suspense fallback='Loading...'><SocialMediaIconLinks/></Suspense>,
        main: () => <Suspense fallback='Loading...'><Blog/></Suspense>
      },
      { path: `/blog/:postName`,
        exact: true,
        sidebar: () => <></>,
        main: () => <Suspense fallback='Loading...'><BlogPost language={this.state.language} platform={this.state.platform}/></Suspense>
      },
      { path: '/confirm/:id',  // this is the only route that's not double in other languages
        sidebar: () => <Suspense fallback='Loading...'><Confirm updateAppState={this.updateAppState} language={this.state.language}/></Suspense>,
        main: () => <Suspense fallback='Loading...'><SocialMediaIconLinks/></Suspense>
      },
      { path: '/app',  // forward visitor to appropriate app store
        sidebar: () => <div></div>,
        main: () => <Suspense fallback='Loading...'><AppLink platform={this.state.platform} /></Suspense>
      },
      { path: '/fr/',                                                 // <- French pages
        exact: true,
        sidebar: () => <Sidebar venue={this.state.venue} authToken={this.state.authToken}
          userID={this.state.userID} toggleBallot={this.toggleBallot}
          updateAppState={this.updateAppState} goToAddress={this.goToAddress}
          isReviewer={this.state.isReviewer} funFact={this.state.funFact}
          language={this.state.language} mapKey={this.state.mapKey}
          platform={this.state.platform} removeChildClassMembers={this.removeChildClassMembers}
          resizeMap={this.resizeMap} level={this.state.level} theme={this.state.theme}/>,
        main: () => <Suspense fallback='Loading...'>
          <Map updateAppState={this.updateAppState} lng={this.state.lng} lat={this.state.lat}
            toggleBallot={this.toggleBallot} authToken={this.state.authToken} mapStyle={this.state.mapStyle}
            bearing={this.state.bearing} zoom={this.state.zoom} theme={this.state.theme}
            language={this.state.language} key={this.state.mapKey} mapFilter= {this.state.mapFilter}
            overlapPoint={this.state.overlapPoint} userID={this.state.userID}
            platform={this.state.platform} removeChildClassMembers={this.removeChildClassMembers}
            reviewHistory={this.state.reviewHistory} getReviewHistory={this.getReviewHistory}/>
        </Suspense>
      },
      { path: '/fr/about',
        sidebar: () => <Suspense fallback='Loading...'><AboutSidebar language={this.state.language}/></Suspense>,
        main: () => <Suspense fallback='Loading...'><About language={this.state.language}/></Suspense>
      },
      { path: '/fr/login',
        sidebar: () => <Suspense fallback='Loading...'><SocialMediaIconLinks/></Suspense>,
        main: () => <Suspense fallback='Loading...'>
          <LoginRegister authToken={this.state.authToken}
            setTheme={this.setTheme} updateAppState={this.updateAppState}
            isRemembered={this.state.isRemembered} language={this.state.language}
            theme={this.state.theme} overlapPoint={this.state.overlapPoint}
            funFact={this.state.funFact} platform={this.state.platform}/>
        </Suspense>
      },
      { path: '/fr/contact',
        sidebar: () => <Suspense fallback='Loading...'><SocialMediaIconLinks/></Suspense>,
        main: () => <Suspense fallback='Loading...'>
          <Contact authToken={this.state.authToken} userID={this.state.userID}
              platform={this.state.platform} language={this.state.language} />
        </Suspense>
      },
      { path: '/fr/user',
        sidebar: () => <></>,
        main: () => <Suspense fallback='Loading...'>
          <UserArea language={this.state.language} userID={this.state.userID}
            level={this.state.level} logOut={this.logOut} reviewHistory={this.state.reviewHistory}
            getReviewHistory={this.getReviewHistory}/>
        </Suspense>
      },
      { path: '/fr/press',
      sidebar: () => <></>,
      main: () => <Press language={this.state.language} platform={this.state.platform} theme={this.state.theme}/>
      }
    ]
  }

  updateRemoteSettings = async () => {
    this.setState({showSettings: false})
    localStorage.setItem('EcoRate_theme', this.state.theme)
    localStorage.setItem('EcoRate_funFact', this.state.funFact)
    localStorage.setItem('EcoRate_language', this.state.language)
    localStorage.setItem('EcoRate_overlapPoint', this.state.overlapPoint)
    const currSetts = {theme: this.state.theme, funFact: this.state.funFact,
      overlapPoint: this.state.overlapPoint, isRemembered: this.state.isRemembered,
      isSubscribed: this.state.isSubscribed}
    if (this.state.userID) {
      const navbarMenu = document.getElementById("navbarMenu")
      navbarMenu.classList.remove('active')
      fetch(`${url}user/updateSettings`, {body: JSON.stringify({userID: this.state.userID, settings: currSetts}),
        method: 'POST', headers: {'Content-Type': 'application/json'}}
      ).then(res => {
        this.setState({userRemoteSettings: currSetts})
      }).catch(err => {
        console.log('Hit an error updating user settings: ', err)
      });
    }
    document.removeEventListener('click', this.toggleNavHelper)
  }

  getUserInfo = async () => {
    if (this.state.userID) {
      await fetch(`${url}user/getUserInfo/${this.state.userID}`)
      .then(res => res.json())
      .then(data => {
        if (data?.message === "Could not get user's review data.") {
          this.logOut()
        } else {
          this.setState({isReviewer: data.isReviewer, level: data.level || 0,
            isSubscribed: data.isSubscribed})
        }
      }).catch(() => console.log('Could not retrieve user info.'))
    }
  }

  toggleNavHelper = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const navButton = document.getElementById("navButton")
    const settingsArea = document.getElementById('settingsArea')
    if (e.target !== navButton && (!settingsArea || !settingsArea.contains(e.target))) {
      const navbarMenu = document.getElementById("navbarMenu")
      navbarMenu.classList.remove('active')
      this.updateRemoteSettings()
    }
  }

  toggleNav = () => {
    const navbarMenu = document.getElementById("navbarMenu")
    if (navbarMenu.classList.contains('active')) {
      navbarMenu.classList.remove('active')
      this.updateRemoteSettings()
    } else {
      navbarMenu.classList.add('active')
      document.addEventListener(`click`, this.toggleNavHelper)
    }
  }

  handleOverlapPoint = () => {
    const overlapSlider = document.getElementById("overlapSlider");
    this.setState({overlapPoint: Number(overlapSlider.value), mapKey: this.state.mapKey + 1})
  }

  componentDidMount = async () => {
    this.setTheme(this.state.theme)
    const root = document.querySelector(':root');
    document.onkeyup = (e) => {
      var e = e || window.event; // for IE to cover IEs window event-object
      if (e.key === 'Escape') {
        const navbarMenu = document.getElementById("navbarMenu")
        if (navbarMenu.classList.contains('active')) {
          this.toggleNav()
        }
      }
    }
    
    if (this.state.platform === 'android') {
      StatusBar.setOverlaysWebView({ overlay: true });
      root.style.setProperty('--statusBarMargin', '24px');
    }
    if (this.state.platform !== 'web') {
      if (this.state.theme === 'dark') {
        StatusBar.setStyle({ style: Style.Dark });
      } else {
        StatusBar.setStyle({ style: Style.Light });
      }
    } else {  // platform === 'web'
      root.style.setProperty('--statusBarMargin', '0px');
      root.style.setProperty('--horizStatusBarMargin', '0px');
    }

    this.getUserInfo()
  }

  updateAppState = updateObj => {
    this.setState(updateObj);
    ['theme', 'overlapPoint', 'language'].forEach(sett => {
      if (updateObj.hasOwnProperty(sett)) {
        localStorage.setItem(`EcoRate_${sett}`, updateObj[sett])
      }
      if (updateObj.hasOwnProperty('overlapPoint')) {
        this.handleOverlapPoint()
      }
    })
    if (updateObj.isRemembered) {
      ['authToken', 'userID', 'isReviewer', 'funFact', 'level'].forEach(sett => {
        if (updateObj.hasOwnProperty(sett)) {
          localStorage.setItem(`EcoRate_${sett}`, updateObj[sett])
        }
      })
    }
  }

  toggleBallot = () => {
    if (!this.state.venue.showBallot) {
      this.getUserInfo()
    }
    this.setState({venue: {...this.state.venue, showBallot: !this.state.venue.showBallot}})
  }

  passToken = () => {
    return {authToken: this.state.authToken, userID: this.state.userID}
  }

  goToAddress = (lng, lat) => {
    if ((lng !== undefined) && (lat !== undefined)) {
      this.state.map.jumpTo({zoom: this.state.zoom,
        center: [lng, lat]
      })
    } else {
      this.state.map.jumpTo({zoom: this.state.zoom,
        center: [this.state.venue.longitude, this.state.venue.latitude]
      })
    }
  }

  setTheme = newTheme => {
    this.setState({theme: newTheme})
    const ndx = Themes[newTheme]
    if (this.state.map) {
      this.state.map.setStyle(MapStyles[ndx])
    }
    this.setState({mapStyle: MapStyles[ndx]})
    const names = Object.keys(Settings)
    for (let i=0; i<names.length; i++) {
      document.documentElement.style.setProperty(names[i], Settings[names[i]][ndx]);
    }
    if (this.state.platform !== 'web') {
      if (newTheme === 'dark') {
        StatusBar.setStyle({ style: Style.Dark });
      } else {
        StatusBar.setStyle({ style: Style.Light });
      }
    }
  }

  removeChildClassMembers = (parentID, className) => {
    let par
    if (parentID.startsWith('.')) {
      par = document.getElementsByClassName(parentID.slice(1))[0]
    } else {
      par = document.getElementById(parentID)
    }
    console.log({par, parentID, className})
    let removeList = par.getElementsByClassName(className);
    for (let m = removeList.length - 1; m >= 0; m--) { // backwards in case there's more than 1 somehow
      removeList[m].remove();
    }
  }

  resizeMap = () => setTimeout(() => {this.state.map.resize()}, 25)

  getReviewHistory = async () => {
    if (!this.state.reviewHistory.length && this.state.userID && this.state.isReviewer) {
      await fetch(`${url}review/getReviewHistory/${this.state.userID}`)
      .then(res => res.json())
      .then(data => {
        this.setState({reviewHistory: data})
        return data
        })
      .catch(err => {
        console.log("We can't get your review history at the moment.")
      })
    } else {
      return this.state.reviewHistory
    }
  }

  logOut = () => {
    const logOutObj = {userID: undefined, authToken: undefined, funFact: false, isReviewer: false, level: 0, reviewHistory: []}
    this.setState(logOutObj)
    Object.keys(logOutObj).forEach((key) => localStorage.removeItem(`EcoRate_${key}`))
  }

  render() {
    return (
      <Router>
        <div id='topLevelContainer'>
          <div className='outerSidebarStyle'>
            <div className='navigation' id='navbarContainer'>
              <Link className='navbarButton' style={{position: 'relative', top: '-9px'}} to={links[this.state.language].homeUrl}>
                <JustLogo text="" language={this.state.language}/>
                <div style={{marginTop: '-4px'}}>Home</div>
              </Link>
              <Link className='navbarButton' to={links[this.state.language].profileUrl}>
                <FontAwesomeIcon icon={faUser} size='lg'/>
                <div>Profile</div>
              </Link>
              <Link className='navbarButton' to={links[this.state.language].blogUrl}>
                <FontAwesomeIcon icon={faPenToSquare} size='lg'/>
                <div>Blog</div>
              </Link>
              <button onClick={this.toggleNav} className="hamburgerMenuIcon navbarButton" id='navButton'>
                <FontAwesomeIcon icon={faBars} style={{pointerEvents: 'none'}} size='lg'/>
                <div style={{fontSize: '15px', pointerEvents: 'none'}}>Menu</div>
              </button>
            </div>
            <NavBarMenu theme={this.state.theme} setTheme={this.setTheme}
              overlapPoint={this.state.overlapPoint} handleOverlapPoint={this.handleOverlapPoint}
              language={this.state.language} isSubscribed={this.state.isSubscribed}
              funFact={this.state.funFact} userID={this.state.userID} level={this.state.level}
              updateAppState={this.updateAppState} showSettings={this.state.showSettings}
            />
            <Switch>
              {this.routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.sidebar />}
                />
              ))}
            </Switch>
          </div>
          <div className='mainContent'>
            <Switch>
              {this.routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
                ))}
              <Suspense fallback='Loading...'><AppLink /></Suspense>
              <Redirect from='*' to='/'/>
            </Switch>
          </div>
        </div>
      </Router>
    )
  }
}

ReactDOM.render(
  <Application />,
  document.getElementById('app'));
