import React, {Suspense, lazy, useState} from 'react'
import {useHistory} from "react-router-dom"
import {CafeBallot} from './ballots/cafe_ballot'
import {MakePopup} from './popup'
import {Hours} from './hours'
import {AddVenue} from './addVenue'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMugHot, faGlassWater, faFaucetDrip, faBottleDroplet} from '@fortawesome/free-solid-svg-icons'
import canCarrier from './images/can_carrier.png'
import ofr from './images/OFR-Icon_Blue.png'
const texts = require('./constants/sidebar.json')
const badges = lazy(() => import('./badge'))
const CupshareBadges = lazy(() => import('./cupshareBadge'))

const FunFactComponent = (props) => {
  const funFacts = texts[props.language].FunFacts
  const dayInt = Math.floor(Date.now() / 86400000) % funFacts.length
  return (
    <div className='funFact'>
      <h1>{funFacts[dayInt][0]}</h1>
      <p className='message'>{funFacts[dayInt][1]}</p>
    </div>
  )
}

const WelcomeComponent = ({language}) => {
  const _texts = texts[language].Welcome
  return (
    <div className='welcome'>
      <h1>{_texts.h1}</h1>
      <p className='message'>{_texts.p1}</p>
    </div>
  )
}

export const Sidebar = props => {
  const [thankYouModalState, setThankYouModalState] = useState('')
  const history = useHistory();
  const _texts = texts[props.language]
  const tym = _texts.ThankYouModal

  const deselectVenueHandler = () => {
    props.updateAppState({venue: {name: '', showBallot: false}})
    props.removeChildClassMembers('mapContainer', 'mapboxgl-popup')
    props.resizeMap()
  }

  const getOFRTenets = () => {
    const OFRTexts = _texts.OFR
    const etc = props.venue.etc.split(';')
    // get the element in etc that starts with 'OFR_'
    const tenets = etc.find(el => el.startsWith('OFR_'))
    if (tenets) {
      const tenetKeys = '1234567' + tenets.split('_')[1]
      return (
        <ul>
          {[...tenetKeys].map((key, i) => (
            <li key={i}>{OFRTexts[key]}</li>
          ))}
        </ul>
      )
    }
  }

  const handleThankYouModal = (val='') => {
    setThankYouModalState(val)
    if (val) {
      setTimeout(() => {  // slight delay is needed because state update is not instantaneous
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 100)
      setTimeout(() => {
        setThankYouModalState('')
        props.updateAppState({venue: {...props.venue, showBallot: false}})
        setTimeout(() => props.resizeMap(), 250)
      }, 30000)
    } else {
      props.updateAppState({venue: {...props.venue, showBallot: false}})
      setTimeout(() => props.resizeMap(), 250)
    }
  }

  let rating
  if (props.venue.isCafeType) {
    rating = props.venue.rating >= 1 ? (Math.round(props.venue.rating * 10 + Number.EPSILON) / 10)
      : (props.venue.name.startsWith('Starbucks') | props.venue.name.startsWith('Dunkin')) ?
        _texts.estimate1 : _texts.beTheFirst
  } else {
    rating = `${(props.language === 'en' ? 
      props.venue.category : _texts.VenueTypes[props.venue.category]) || _texts.unknown}`
  }

  return (
    <div className='innerSidebarContainer' id='innerSidebarContainer'>
      <div className={`sidebarStyle modalShowing-${Boolean(thankYouModalState)} maximize-${props.venue.showBallot}`}>
        {props.venue?.adding === true ?
        <AddVenue updateAppState={props.updateAppState} language={props.language}
          venue={props.venue} userID={props.userID} platform={props.platform} resizeMap={props.resizeMap}/> :
        props.venue.name === '' ?
        <>
          {((props.isReviewer === true) && (props.funFact)) ?
          <FunFactComponent language={props.language}/> :
          <WelcomeComponent language={props.language}/> 
          }
        </> :
        <div className='venueCard'>
          <div className='venueHeader'>
            <div className='venueHeaderInfo'>
              <h2 className='venueHeaderItem'>{props.venue.name}</h2>
              <h3 className={`venueHeaderItem display-${!props.venue.showBallot}`}>
                {rating}
                {(props.venue.isCafeType && props.venue.rating) ?
                  <span role="img" aria-label="Star">⭐</span> : ''}
                {props.venue?.website ?
                  <a className="websiteLink" href={props.venue.website} rel="noopener nofollow" target="_blank">&nbsp;🔗</a>: ''}
              </h3>
              <div className={`badgeRow display-${!props.venue.showBallot}`}>
                {props.venue?.etc && props.venue?.etc.includes('OFR_') &&
                <button className='hasPopup badge' id='OFRBadge' title={_texts.ofrTitle} role='img'
                  onClick={e => MakePopup({parentID: 'OFRBadge', text: _texts.ofrPopupText,
                  _to: "https://www.surfrider.org/programs/ocean-friendly-restaurants", _link: `${_texts.ofrLink} 🔗`})}>
                  <img loading='lazy' id='OFRBadge' alt='Ocean Friendly Restaurant' src={ofr} width='30'/>
                </button>}
                {props.venue?.usesHotMug &&
                <button className='hasPopup badge' id='mugBadge' title={_texts['mugPopupText']}
                  onClick={e => MakePopup({parentID: 'mugBadge', text: _texts['mugPopupText']})} role='img'>
                <FontAwesomeIcon icon={faMugHot}/></button>}
                {props.venue?.pfr &&
                <button className='hasPopup badge' id='PFRLogoBtn' title={_texts.pfrLink} role='img'
                  onClick={e => MakePopup({parentID: 'PFRLogoBtn', text: _texts.pfrPopupText,
                  _to: "https://www.plasticfreerestaurants.org/", _link: `${_texts.pfrLink} 🔗`})}>
                86</button>}
                {props.venue?.allowsHotThermos &&
                <button className='hasPopup badge' id='thermosBadge' title={_texts.thermosTitle} role='img'
                  onClick={e => MakePopup({parentID: 'thermosBadge', text: _texts.thermosText})}>
                <FontAwesomeIcon icon={faBottleDroplet}/></button>}
                {props.venue?.hotContactlessPour &&
                <button className='hasPopup badge' id='contactlessPourBadge' title={_texts.contactlessText} role='img'
                  onClick={e => MakePopup({parentID: 'contactlessPourBadge', text: _texts.contactlessText})}>
                ⚕️</button>}
                {props.venue?.reusableColdCup &&
                <button className='hasPopup badge' id='coldCupBadge' title={_texts.coldCupText} role='img'
                  onClick={e => MakePopup({parentID: 'coldCupBadge', text: _texts.coldCupText})}>
                <FontAwesomeIcon icon={faGlassWater}/></button>}
                {props.venue?.reusableWaterCup &&
                <button className='hasPopup badge' id='waterCupBadge' title={_texts.waterCupText} role='img'
                  onClick={e => MakePopup({parentID: 'waterCupBadge', text: _texts.waterCupText})}>
                <FontAwesomeIcon icon={faFaucetDrip}/></button>}
                {props.venue?.strawless &&
                <button className='hasPopup badge' id='strawlessBadge' title={_texts.strawlessTitle} role='img'
                  onClick={e => MakePopup({parentID: 'strawlessBadge', text: _texts.strawlessText})}>
                🐢</button>}
                {props.venue?.composting &&
                <button className='hasPopup badge' id='compostingBadge' title={_texts.compostingText} role='img'
                  onClick={e => MakePopup({parentID: 'compostingBadge', text: _texts.compostingText})}>
                🌱</button>}
                {props.venue?.recycling &&
                <button className='hasPopup badge' id='recyclingBadge' title={_texts.recyclingText} role='img'
                  onClick={e => MakePopup({parentID: 'recyclingBadge', text: _texts.recyclingText})}>
                ♻️</button>}
                {props.venue?.deposit &&
                <button className='hasPopup badge' id='depositBadge' title={_texts.depositText} role='img'
                  onClick={e => MakePopup({parentID: 'depositBadge', text: _texts.depositText})}>
                🏺</button>}
                {props.venue.cupShare &&
                <Suspense fallback="Loading..."><CupshareBadges cupshare={props.venue.cupShare} language={props.language} platform={props.platform} theme={props.theme}></CupshareBadges></Suspense>}
                {props.venue?.etc && props.venue?.etc.includes('can carrier') &&
                <button className='hasPopup badge' id='canCarrierBadge' title={_texts.canCarrierText} role='img'
                   onClick={e => MakePopup({parentID: 'canCarrierBadge', text: _texts.canCarrierText})}>
                  <img loading='lazy' id='CanCarrier' alt='Can Carrier Dropoff Site' src={canCarrier} width='30'/>
                </button>}
                {props.venue?.etc && (props.venue.etc.includes('community jars')) &&
                  <button className='hasPopup badge' id='communityJarsBadge' title={_texts.communityJarsText} role='img'
                  onClick={e => MakePopup({parentID: 'communityJarsBadge', text: _texts.communityJarsText})}>
                🫙</button>}
              {props.venue?.etc && (props.venue.etc.includes('deposit jars')) &&
                  <button className='hasPopup badge' id='depositJarsBadge' title={_texts.depositJarsText} role='img'
                    onClick={e => MakePopup({parentID: 'depositJarsBadge', text: _texts.depositJarsText})}>
                  🏺</button>}
              </div>
            </div>
          </div>
          <div className={`display-${!props.venue.showBallot}`}>{props.venue.address}
            <button title={_texts.addressBtnTitle} className='addressBtn boxShadowFocus' type='button'
              onClick={props.goToAddress}><span role="img" aria-label="Up-Right arrow">↗️</span></button>
          </div>
          {props.venue?.etc && props.venue?.etc.includes('OFR_') && !props.venue.showBallot &&
            <details className='OFRDetails' style={{marginTop: '10px'}}>
              <summary>
                <img style={{marginBottom: '-10px'}} loading='lazy' id='OFRBadge' alt='Ocean Friendly Restaurant' src={ofr} width='30'/>
                {_texts.OFR.ofrDetailsSummary}
              </summary>
              {getOFRTenets()}
            </details>
          }
          {props.venue?.hours && !props.venue.showBallot &&
            <Hours hours={props.venue.hours} language={props.language} resizeMap={props.resizeMap}/>}
          <div className={`infoBox display-${!props.venue.showBallot}`}>
            {props.venue?.beans && <div id='beans' className='venueInfo' onClick={e => MakePopup(
            {parentID: 'beans', text: (_texts['beansPopupText'] + props.venue.beans)})}>
            • ☕ {props.venue.beans}</div>}
            {(props.venue?.seating && props.venue.seating.includes('i')) &&
            <div className='venueInfo'>• Indoor seating</div>}
            {(props.venue?.seating && props.venue.seating.includes('h')) ?
            <div className='venueInfo'>• Heated outdoor seating</div>
            : (props.venue?.seating && props.venue.seating.includes('o')) ? 
            <div className='venueInfo'>• Outdoor seating</div> : ''}
          </div>
          {(props.venue.showBallot && props.authToken && props.venue.isCafeType) ?
          <>
            <p className='message' style={{marginTop: '30px'}}>{_texts.sureAnswers}</p>
            <CafeBallot authToken={props.authToken} userID={props.userID}
              venue={props.venue} toggleBallot={props.toggleBallot}
              handleThankYouModal={handleThankYouModal} language={props.language}
              isReviewer={props.isReviewer} updateAppState={props.updateAppState}
              deselectVenueHandler={deselectVenueHandler} level={props.level}/>
          </> :
          (props.authToken && props.venue.isCafeType) ?
          <div className='flexEl'>
            <button id='sidebarReviewButton' type="button" className="reviewButton boxShadowFocus"
              onClick={props.toggleBallot} title={_texts.reviewBtnTitle}>{_texts.reviewBtn}</button>
          </div> :
          props.venue.isCafeType ?
          <div className='flexEl'>
            <p className='centeredMessage'>
              {_texts.loginLink1} <button className='linkToLogin' title={_texts.loginLinkTitle}
              onClick={() => history.push(_texts.loginAddress)}>{_texts.loginLink2}</button>
              {_texts.loginLink3}
            </p>
          </div> :
          <></>}
        </div>
        }
        <button className={`deselectVenue boxShadowFocus display-${props.venue.name !== ''}`}
          onClick={deselectVenueHandler} title={_texts.deselect}>&#10005;</button>
      </div>
      {Boolean(thankYouModalState) &&
      <div className={`modalThankYou modalShowing-${Boolean(thankYouModalState)}`}
        id='modalThankYou' title={tym[thankYouModalState]?.title || ''}>
        <h1>{tym[thankYouModalState]?.h1}</h1>
        <p>{tym[thankYouModalState]?.p}</p>
        <p>{`~${tym[thankYouModalState]?.proverb}~`}</p>
        <div className={`tymBadgeContainer display-${Boolean(thankYouModalState)}`}>
          {thankYouModalState !== 'noBadge' && <Suspense fallback="Loading">{badges[thankYouModalState]}</Suspense>}
        </div>
        <button className='closeBtn boxShadowFocus' title={_texts.close}
          onClick={e => handleThankYouModal('')}>{_texts.close}
        </button>
      </div>
      }
    </div>
  )
};
