import { Capacitor } from '@capacitor/core'

const RemoveLinkPopup = e => {
  const popups = document.getElementsByClassName('linkPopup')
  Object.values(popups).forEach(popup => {
    const pseudoParent = popup.parentElement
    pseudoParent.parentElement.removeChild(pseudoParent)})
  document.body.removeEventListener('click', RemoveLinkPopup)
}

export const MakePopup = ({_to, parentID, text, _link, size='medium', l_r=''}) => {
  RemoveLinkPopup()
  const parent = document.getElementById(parentID)
  const child = document.createElement('div')
  const platform = Capacitor.getPlatform();
  const top_bottom = (parentID !== 'OFRBadge' && (platform === 'ios' || platform === 'android')) ? 'pop-up' : 'pop-down'
  const boundingRect = parent.getBoundingClientRect()
  const left_right = l_r ? l_r : (boundingRect.left > 150) ? 'pop-left' : 'pop-right'

  child.innerHTML = `
    <div class='${size}-popup ${top_bottom} ${left_right} linkPopup'>${text}
      ${_link ? `<a class='popupLink' href=${_to} rel="noopener nofollow" target="_blank"
        title=${_to}>${_link}</a>` : ''}
      <div class='popupCarrot carrot-${top_bottom} carrot-${left_right}'></div>
    </div>`
  parent.appendChild(child);
  setTimeout(e => {document.body.addEventListener('click', RemoveLinkPopup)}, 50)
}
