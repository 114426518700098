import React from 'react';
import { MakePopup } from '../popup';

const BackBtn = ({language, backHandler}) => {
  return(
    <button className='cafeBallotBtn backBtn' onClick={backHandler}>
      {language === 'en' ? 'Back' : 'Précédent'}
    </button>
  )
}

const NextBtn = ({language, getters, setter, setters, qID, extraHandler}) => {
  const nextQuestionHandler = () => {
    if (setter) {
      setter('Skipped')
    } else {
      for (let i=0; i<getters.length; i++) {
        if (!getters[i]) {
          setters[i]('Skipped')
        }
      }
    }
    if (extraHandler) extraHandler();
  }

  let _disabled = false
  if (['gotHotDrink'].indexOf(qID) > -1) {  // at least 1 selection required
    if (getters.every(choice => !choice)) {
      _disabled = true
    }
  } else if (['hotCupType', 'coldCupType'].indexOf(qID) > -1) { // required questions
    _disabled = true
  } else if (["isHotReusableUsed", 'isColdReusableUsed'].indexOf(qID) > -1) {  // at least one 'Yes' required
    if (getters.every(choice => choice !== 'Yes')) {
      _disabled = true
    }
  }

  let _title
  if (language === 'fr') {
    _title = _disabled ? 'Cette question ne peut pas être sautée' : 'Question suivante'
  } else {
    _title = _disabled ? "This question can't be skipped" : 'Next question'
  }

  return(
    <button className={`cafeBallotBtn nextQBtn disabledBtn-${_disabled}`}
      disabled={_disabled} title={_title}
      onClick={e => nextQuestionHandler(getters, setter, setters, extraHandler)}>
      {language === 'en' ? (qID === 'composting' ? 'Done' : 'Next')
        : (qID === 'composting' ? 'Finir' : 'Suivant')}
    </button>
  )
}


export const QuestionCard = ({prompt, getter, setter, getters, setters,
                              choices, values, id, qName, backHandler,
                              extraHandler, language, skipButtons}) => {
  /* The optional `values` parameter lets you send a shortened version of the answer the user 
  selects as the value actually saved in the database. This can also be used to avoid sending 
  things like emojis to the database.

  Example: choices=['Yes ❤️', 'No'] values=['Yes', 'No']
  -> if the user selects the 'Yes ❤️' option, 'Yes' is what will be saved to the database.

  Passing a truthy value for skipButtons omits the Back and Next buttons.
  */
  if (!choices) {
    choices = values
  }
  const elements = []
  let radioHandler
  if (setter) {
    radioHandler = e => {
      setter(e.target.value)
      if (extraHandler) {
        extraHandler()
      }
    }
  }
  let checked

  for (let i=0; i<choices.length; i++) {
    if (setter) {
      checked = getter === values[i]
    } else {
      checked = getters[i] === values[i]
    }
    elements.push(<button title={choices[i]} onClick={setter ? radioHandler :  // radio-button func
        e => getters[i] === e.target.value ? setters[i]('') : setters[i](e.target.value)}  // checkbox-button func
        className={`settingOption boxShadowFocus checked-${checked}`}
        name={qName} value={values[i]}>{choices[i]}
      </button>
    )
  }

  return(
    <div className='questionCard fadeIn' key={id} id={id} title={qName}>
      <h3>{prompt}</h3>
      <div className='questionOptions' key={`${id}-${setter ? 'radios' : 'checkboxes'}`}>
        {elements}
      </div>
      {!skipButtons &&
      <div className='backNextContainer'>
        <BackBtn language={language} backHandler={backHandler}/>
        <NextBtn language={language} setter={setter} getters={getters}
          setters={setters} qID={id} extraHandler={extraHandler}/>
      </div>}
    </div>
  )
}


export const SettingLabel = ({qName, prompt, tipText, openSetting, activeSetting, size='medium'}) => {
  return(
    <div className='settingLabel' id={qName} onClick={e => openSetting(qName)}>{prompt}&nbsp;&nbsp;
      {tipText &&
      <button id={`${qName}-tipBtn`} className='hasPopup tooltipBtn' role="img" title={tipText}
        onClick={e => MakePopup({parentID: `${qName}-tipBtn`, text: tipText, size})}>?</button>}
      <span className='openCloseLabel'>{activeSetting === qName ? '➖' : '➕'}</span>
    </div>
  )
}


export const SettingsRadioQuestion = ({getter, setter, choices, values, id,
                                       qName, accordionGetter}) => {
  /*See QuestionCard component doc string for explanation of some of these 
  parameters */
  if (!choices) {
    choices = values
  }
  const elements = []

  for (let i=0; i<choices.length; i++) {
    elements.push(<button title={choices[i]} onClick={e => setter(e.target.value)}
        className={`settingOption boxShadowFocus checked-${getter === values[i]}`}
        name={qName} value={values[i]} key={qName + '__' + i}>{choices[i]}
      </button>
    )
  }

  return(
    <div className={`settingQuestionCard display-${accordionGetter === qName}`}
      key={id} id={id} title={qName}>
      <div className='questionOptions' key={`${id}-radios`}>
        {elements}
      </div>
    </div>
  )
}


export const GridFormComponent = ({prompt, gridKey, rows, cols, values, getters, setters,
                                   id, qName, backHandler, extraHandler, language}) => {
  if (!cols) {
    cols = values
  }
  const contentElements = [
    <div className='colLabel' key={`${gridKey}-top-left`}>
      <span role="img" aria-label="Memo">📝</span>
    </div>]
  for (let i=0; i<cols.length; i++) {
    contentElements.push(<div className='colLabel' key={`${gridKey}-${cols[i]}`}>{cols[i]}</div>)
  }
  for (let r=0; r<rows.length; r++) {
    let evenOdd = r % 2 === 0 ? 'even-row' : 'odd-row'
    contentElements.push(<div className={`rowLabel ${evenOdd}`} key={`${gridKey}-${rows[r]}`}>{rows[r]}</div>)
    for (let c=0; c<cols.length; c++) {
      contentElements.push(
        <div className={`cell ${evenOdd}`} key={`${gridKey}-cell-${r}-${c}`}>
          <input className="checkbox" type="checkbox"
            checked={getters[r] === values[c]} title={`${rows[r]}: ${cols[c]}`} 
            onChange={e => getters[r] === values[c] ? setters[r]('') : setters[r](values[c])}/>
        </div>
      )
    }
  }

  return (
    <div className='gridFormQuestionCard questionCard fadeIn'>
      <h3>{prompt}</h3>
      <div className={`gridForm cols-${cols.length} questionCard fadeIn`}
        key={`gridForm-${gridKey}`} id={id} title={qName}>
        {contentElements}
      </div>
      <div className='backNextContainer' id='backNextContainer'>
        <BackBtn language={language} backHandler={backHandler}/>
        <NextBtn language={language} getters={getters} setters={setters} qID={id} extraHandler={extraHandler}/>
      </div>
    </div>
  )
}
