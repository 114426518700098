import {Capacitor} from "@capacitor/core"
import {Keyboard} from '@capacitor/keyboard'

const useKeyboard = () => {
    const manageKeyboard = (scrollToId='', resizeMap) => {   
        // hide bottom navbar while keyboard is showing, and scroll the `scrollToId` element to top if given
        if (Capacitor.isNativePlatform()) {
            Keyboard.addListener('keyboardDidShow', info => {
                const bottomNav = document.getElementById('navbarContainer')
                bottomNav.style.display = 'none'
                const socialMediaLogos = document.getElementById('socialMediaLogos')
                socialMediaLogos.style.display = 'none'
                if (scrollToId) {
                    const element = document.getElementById(scrollToId)
                    element.scrollIntoView(true, {behavior: 'smooth'})
                }
                if(Capacitor.getPlatform() === 'android') {
                    const sidebarStyleDiv = document.getElementsByClassName('sidebarStyle')[0]
                    sidebarStyleDiv.style.setProperty('padding-bottom', `${info.keyboardHeight}px`)
                }
            })
            
            Keyboard.addListener('keyboardWillHide', info => {
                const bottomNav = document.getElementById('navbarContainer')
                bottomNav.style.display = 'flex'
                const socialMediaLogos = document.getElementById('socialMediaLogos')
                socialMediaLogos.style.display = 'flex'
                const sidebarStyleDiv = document.getElementsByClassName('sidebarStyle')[0]
                sidebarStyleDiv.style.setProperty('padding-bottom', `5px`)
                // resize map to readjust after keyboard is hidden
                if (typeof resizeMap === 'function') {
                    resizeMap()
                }
            })
        }
    }
    return manageKeyboard
}

export default useKeyboard