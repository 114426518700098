import React, {useState, useEffect} from 'react'
import './cafeBallotStyle.css'
import {QuestionCard, GridFormComponent} from './generic_ballot_components'
const cafe_review_keys = require("../constants/cafe_review_keys.json")
const {REVIEW_KEYS, PARAMS} = cafe_review_keys
const _ = require("lodash")
const url = process.env.NODE_ENV === 'production' ? 'https://ecorate.eco/' : 'http://localhost:5000/'
if (!Date.now) {  // for compatibility with IE8
  Date.now = () => new Date().getTime()
}

const HOT_CUP_ROW_LABELS = ['Reusable', 'Thermos', 'Paper', 'Compst.', 'Styro.']
const HOT_CUP_ROW_VALUES = ['Reusable', 'Thermos', 'Paper', 'Compostable', 'Styrofoam']
const HOT_TO_GO_CUP_ROW_LABELS = ['Cup-share prog.', 'Cup w/ deposit', 'Thermos', 'Paper', 'Compst.', 'Styro.']
const HOT_TO_GO_CUP_ROW_VALUES = ['Cup-share program', 'Deposit', 'Thermos', 'Paper', 'Compostable', 'Styrofoam']
const HOT_CUP_ROW_LABELS_FR = ['Réutilisable', 'Thermos', 'Papier', 'Compostable', 'Polystyrène']
const HOT_TO_GO_CUP_ROW_LABELS_FR = ['Prog. tasse commune', 'Tasse avec dépôt', 'Thermos', 'Papier', 'Compostable', 'Polystyrène']

const COLD_CUP_ROW_LABELS = ['Reusable', 'Thermos', 'Plastic', 'Paper', 'Compst.', 'Styro.']
const COLD_CUP_ROW_VALUES = ['Reusable', 'Thermos', 'Plastic', 'Paper', 'Compostable', 'Styrofoam']
const COLD_TO_GO_CUP_ROW_LABELS = ['Cup-share prog.', 'Cup w/ deposit', 'Thermos', 'Plastic', 'Paper', 'Compst.', 'Styro.']
const COLD_TO_GO_CUP_ROW_VALUES = ['Cup-share program', 'Deposit', 'Thermos', 'Plastic', 'Paper', 'Compostable', 'Styrofoam']
const COLD_CUP_ROW_LABELS_FR = ['Réutilisable', 'Thermos', 'Plastique', 'Papier', 'Compostable', 'Polystyrène']
const COLD_TO_GO_CUP_ROW_LABELS_FR = ['Prog. tasse commune', 'Tasse avec dépôt', 'Thermos', 'Plastique', 'Papier', 'Compostable', 'Polystyrène']


export const CafeBallot = props => {
  const stateHooks = [
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState(''),
    useState('')
  ];

  const [
    [orderType, setOrderType],
    [indoorSeating, setIndoorSeating],
    [outdoorSeating, setOutdoorSeating],
    [heatedOutdoorSeating, setHeatedOutdoorSeating],
    [gotHotDrink, setGotHotDrink],
    [gotColdDrink, setGotColdDrink],
    [gotTapWater, setGotTapWater],
    [gotFood, setGotFood],
    [isHotToGoCupShareUsed, setIsHotToGoCupShareUsed],
    [isHotToGoDepositCupUsed, setIsHotToGoDepositCupUsed],
    [isHotReusableUsed, setIsHotReusableUsed],
    [isHotThermosUsed, setIsHotThermosUsed],
    [isHotPaperUsed, setIsHotPaperUsed],
    [isHotCompostableUsed, setIsHotCompostableUsed],
    [isHotStyrofoamUsed, setIsHotStyrofoamUsed],
    [hotToGoCupShareFeeDiscount, setHotToGoCupShareFeeDiscount],
    [hotToGoDepositCupFeeDiscount, setHotToGoDepositCupFeeDiscount],
    [hotReusableFeeDiscount, setHotReusableFeeDiscount],
    [hotThermosFeeDiscount, setHotThermosFeeDiscount],
    [hotPaperFeeDiscount, setHotPaperFeeDiscount],
    [hotCompostableFeeDiscount, setHotCompostableFeeDiscount],
    [hotStyrofoamFeeDiscount, setHotStyrofoamFeeDiscount],
    [hotCupPromotion, setHotCupPromotion],
    [hotCupType, setHotCupType],
    [hotCupDefault, setHotCupDefault],
    [hotThermosFill, setHotThermosFill],
    [hotDoubleCup, setHotDoubleCup],
    [hotLidType, setHotLidType],
    [hotSleevePolicy, setHotSleevePolicy],
    [hotSleeveType, setHotSleeveType],
    [stirrerType, setStirrerType],
    [isColdToGoCupShareUsed, setIsColdToGoCupShareUsed],
    [isColdToGoDepositCupUsed, setIsColdToGoDepositCupUsed],
    [isColdReusableUsed, setIsColdReusableUsed],
    [isColdThermosUsed, setIsColdThermosUsed],
    [isColdPlasticUsed, setIsColdPlasticUsed],
    [isColdPaperUsed, setIsColdPaperUsed],
    [isColdCompostableUsed, setIsColdCompostableUsed],
    [isColdStyrofoamUsed, setIsColdStyrofoamUsed],
    [coldToGoCupShareFeeDiscount, setColdToGoCupShareFeeDiscount],
    [coldToGoDepositCupFeeDiscount, setColdToGoDepositCupFeeDiscount],
    [coldReusableFeeDiscount, setColdReusableFeeDiscount],
    [coldThermosFeeDiscount, setColdThermosFeeDiscount],
    [coldPlasticFeeDiscount, setColdPlasticFeeDiscount],
    [coldPaperFeeDiscount, setColdPaperFeeDiscount],
    [coldCompostableFeeDiscount, setColdCompostableFeeDiscount],
    [coldStyrofoamFeeDiscount, setColdStyrofoamFeeDiscount],
    [coldCupPromotion, setColdCupPromotion],
    [coldCupType, setColdCupType],
    [coldCupDefault, setColdCupDefault],
    [coldThermosFill, setColdThermosFill],
    [coldLidType, setColdLidType],
    [coldSleevePolicy, setColdSleevePolicy],
    [coldSleeveType, setColdSleeveType],
    [coldStrawType, setColdStrawType],
    [waterCupType, setWaterCupType],
    [waterLid, setWaterLid],
    [waterStraw, setWaterStraw],
    [plateType, setPlateType],
    [cutleryType, setCutleryType],
    [hasPlasticBottles, setHasPlasticBottles],
    [recycling, setRecycling],
    [composting, setComposting]
  ] = stateHooks;

  const firstSeatingIndex = REVIEW_KEYS.indexOf("indoorSeating")
  const firstGotQuestionIndex = REVIEW_KEYS.indexOf("gotHotDrink", firstSeatingIndex)
  const lastGotQuestionIndex = REVIEW_KEYS.indexOf("gotFood", firstGotQuestionIndex)

  const hotCupShareIndex = REVIEW_KEYS.indexOf("isHotToGoCupShareUsed", lastGotQuestionIndex)
  const hotReusableIndex = REVIEW_KEYS.indexOf("isHotReusableUsed", hotCupShareIndex)
  const hotLastIndex = REVIEW_KEYS.indexOf("isHotStyrofoamUsed", hotReusableIndex)
  const hotCupShareFDIndex = REVIEW_KEYS.indexOf("hotToGoCupShareFeeDiscount", hotLastIndex)
  const hotReusableFDIndex = REVIEW_KEYS.indexOf("hotReusableFeeDiscount", hotCupShareFDIndex)
  const hotLastFDIndex = REVIEW_KEYS.indexOf("hotStyrofoamFeeDiscount", hotReusableFDIndex)
  const hotCupTypeIndex = REVIEW_KEYS.indexOf('hotCupType', hotLastFDIndex)
  const stirrerTypeIndex = REVIEW_KEYS.indexOf('stirrerType', hotCupTypeIndex)

  const hotCupAvailabilityGetters = []
  const hotCupAvailabilitySetters = []
  const hotToGoCupAvailabilityGetters = [isHotToGoCupShareUsed, isHotToGoDepositCupUsed]
  const hotToGoCupAvailabilitySetters = [setIsHotToGoCupShareUsed, setIsHotToGoDepositCupUsed]
  for (let i=hotReusableIndex; i<=hotLastIndex; i++) {
    hotCupAvailabilityGetters.push(stateHooks[i][0])
    hotCupAvailabilitySetters.push(stateHooks[i][1])
    if (i > hotReusableIndex) {  // don't add Reusable to the to-go stuff
      hotToGoCupAvailabilityGetters.push(stateHooks[i][0])
      hotToGoCupAvailabilitySetters.push(stateHooks[i][1])
    }
  }

  const hCAG = () => {  // hCAG = hot(ToGo)CupAvailabilityGetters
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      return hotCupAvailabilityGetters
    } else {
      return hotToGoCupAvailabilityGetters
    }
  }

  const hCAS = () => {  // hCAS = hot(ToGo)CupAvailabilitySetters
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      return hotCupAvailabilitySetters
    } else {
      return hotToGoCupAvailabilitySetters
    }
  }

  const hotFeeDiscountRows = () => {
    const fDRows = []
    const langRowLabels = props.language === 'en' ? HOT_CUP_ROW_LABELS : HOT_CUP_ROW_LABELS_FR
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      for (let g=0; g<5; g++) {
        if (hotCupAvailabilityGetters[g] === 'Yes') {
          fDRows.push(langRowLabels[g])
        }
      }
      // Also try to set hotCupType hotCupDefault, and hotFeeDiscount for the cup type used if possible
      // The Date.now condition is to disable this if the user just pushed the back button
      if (fDRows.length === 1 && hotCupType === '' && (Date.now() - lastBackTime > 500)) {
        const cupUsed = HOT_CUP_ROW_VALUES[langRowLabels.indexOf(fDRows[0])]
        setHotCupType(cupUsed)
        const counts = _.countBy([isHotReusableUsed, isHotPaperUsed, isHotCompostableUsed, isHotStyrofoamUsed])
        if (counts?.No === 3) {
          setHotCupDefault(cupUsed)
          hotFeeDiscountSetters()[0]('No/Unsure')
        }
      }
    } else {
      const langToGoRowLabels = props.language === 'en' ? HOT_TO_GO_CUP_ROW_LABELS : HOT_TO_GO_CUP_ROW_LABELS_FR
      for (let g=0; g<6; g++) {
        if (hotToGoCupAvailabilityGetters[g] === 'Yes') {
          fDRows.push(langToGoRowLabels[g])
        }
      }
      if (fDRows.length === 1 && hotCupType === '' && (Date.now() - lastBackTime > 500)) {
        const cupUsed = HOT_TO_GO_CUP_ROW_VALUES[langToGoRowLabels.indexOf(fDRows[0])]
        setHotCupType(cupUsed)
        const counts = _.countBy([isHotToGoDepositCupUsed, isHotToGoCupShareUsed, isHotPaperUsed, isHotCompostableUsed, isHotStyrofoamUsed])
        if (counts?.No === 4) {
          setHotCupDefault(cupUsed)
          hotFeeDiscountSetters()[0]('No/Unsure')
        }
      }
    }
    return fDRows
  }

  const hotFeeDiscountGetters = () => {
    const fDGetters = []
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      for (let g=0; g<5; g++) {
        if (hotCupAvailabilityGetters[g] === 'Yes') {
          fDGetters.push(stateHooks[hotReusableFDIndex + g][0])
        }
      }
    } else {
      const getterOptions = [hotToGoCupShareFeeDiscount, hotToGoDepositCupFeeDiscount,
        hotThermosFeeDiscount, hotPaperFeeDiscount, hotCompostableFeeDiscount, hotStyrofoamFeeDiscount
      ]
      for (let g=0; g<6; g++) {
        if (hotToGoCupAvailabilityGetters[g] === 'Yes') {
          fDGetters.push(getterOptions[g])
        }
      }
    }
    return fDGetters
  }

  const hotFeeDiscountSetters = () => {
    const fDSetters = []
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      for (let g=0; g<5; g++) {
        if (hotCupAvailabilityGetters[g] === 'Yes') {
          fDSetters.push(stateHooks[hotReusableFDIndex + g][1])
        }
      }
    } else {
      const setterOptions = [setHotToGoCupShareFeeDiscount, setHotToGoDepositCupFeeDiscount,
        setHotThermosFeeDiscount, setHotPaperFeeDiscount, setHotCompostableFeeDiscount,
        setHotStyrofoamFeeDiscount
      ]
      for (let g=0; g<6; g++) {
        if (hotToGoCupAvailabilityGetters[g] === 'Yes') {
          fDSetters.push(setterOptions[g])
        }
      }
    }
    return fDSetters
  }

  const coldCupShareIndex = REVIEW_KEYS.indexOf("isColdToGoCupShareUsed", stirrerTypeIndex)
  const coldReusableIndex = REVIEW_KEYS.indexOf("isColdReusableUsed", coldCupShareIndex)
  const coldLastIndex = REVIEW_KEYS.indexOf("isColdStyrofoamUsed", coldReusableIndex)
  const coldCupShareFDIndex = REVIEW_KEYS.indexOf("coldToGoCupShareFeeDiscount", coldLastIndex)
  const coldReusableFDIndex = REVIEW_KEYS.indexOf("coldReusableFeeDiscount", coldCupShareFDIndex)
  const coldLastFDIndex = REVIEW_KEYS.indexOf("coldStyrofoamFeeDiscount", coldReusableFDIndex)
  const coldCupTypeIndex = REVIEW_KEYS.indexOf('coldCupType', coldLastFDIndex)
  const coldStrawTypeIndex = REVIEW_KEYS.indexOf("coldStrawType", coldCupTypeIndex)
  const waterStrawIndex = REVIEW_KEYS.indexOf("waterStraw", coldStrawTypeIndex)
  const cutleryTypeIndex = REVIEW_KEYS.indexOf("cutleryType", waterStrawIndex)

  const coldCupAvailabilityGetters = []
  const coldCupAvailabilitySetters = []
  const coldToGoCupAvailabilityGetters = [isColdToGoCupShareUsed, isColdToGoDepositCupUsed]
  const coldToGoCupAvailabilitySetters = [setIsColdToGoCupShareUsed, setIsColdToGoDepositCupUsed]
  for (let i=coldReusableIndex; i<=coldLastIndex; i++) {
    coldCupAvailabilityGetters.push(stateHooks[i][0])
    coldCupAvailabilitySetters.push(stateHooks[i][1])
    if (i > coldReusableIndex) {  // don't add Reusable to the to-go stuff
      coldToGoCupAvailabilityGetters.push(stateHooks[i][0])
      coldToGoCupAvailabilitySetters.push(stateHooks[i][1])
    }
  }

  const cCAG = () => {  // cCAG = cold(ToGo)CupAvailabilityGetters
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      return coldCupAvailabilityGetters
    } else {
      return coldToGoCupAvailabilityGetters
    }
  }

  const cCAS = () => {  // cCAS = cold(ToGo)CupAvailabilitySetters
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      return coldCupAvailabilitySetters
    } else {
      return coldToGoCupAvailabilitySetters
    }
  }

  const coldFeeDiscountRows = () => {
    const fDRows = []
    const langRowLabels = props.language === 'en' ? COLD_CUP_ROW_LABELS : COLD_CUP_ROW_LABELS_FR
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      for (let g=0; g<6; g++) {
        if (coldCupAvailabilityGetters[g] === 'Yes') {
          fDRows.push(langRowLabels[g])
        }
      }
      // Also try to set coldCupType coldCupDefault, and coldFeeDiscount for the cup type used if possible
      // The Date.now condition is to disable this if the user just pushed the back button
      if (fDRows.length === 1 && coldCupType === '' && (Date.now() - lastBackTime > 500)) {
        const cupUsed = COLD_CUP_ROW_VALUES[langRowLabels.indexOf(fDRows[0])]      
        setColdCupType(cupUsed)
        let counts = _.countBy([isColdReusableUsed, isColdPlasticUsed, isColdPaperUsed, isColdCompostableUsed, isColdStyrofoamUsed])
        if (counts?.No === 4) {
          setColdCupDefault(cupUsed)
          coldFeeDiscountSetters()[0]('No/Unsure')
        }
      }
    } else {
      const langToGoRowLabels = props.language === 'en' ? COLD_TO_GO_CUP_ROW_LABELS : COLD_TO_GO_CUP_ROW_LABELS_FR
      for (let g=0; g<7; g++) {
        if (coldToGoCupAvailabilityGetters[g] === 'Yes') {
          fDRows.push(langToGoRowLabels[g])
        }
      }

      if (fDRows.length === 1 && coldCupType === '' && (Date.now() - lastBackTime > 500)) {
        const cupUsed = COLD_TO_GO_CUP_ROW_VALUES[langToGoRowLabels.indexOf(fDRows[0])]
        setColdCupType(cupUsed)
        const counts = _.countBy([isColdToGoDepositCupUsed, isColdToGoCupShareUsed, isColdPlasticUsed, isColdPaperUsed, isColdCompostableUsed, isColdStyrofoamUsed])
        if (counts?.No === 5) {
          setColdCupDefault(cupUsed)
          coldFeeDiscountSetters()[0]('No/Unsure')
        }
      }
    }
    return fDRows
  }

  const coldFeeDiscountGetters = () => {
    const fDGetters = []
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      for (let g=0; g<6; g++) {
        if (coldCupAvailabilityGetters[g] === 'Yes') {
          fDGetters.push(stateHooks[coldReusableFDIndex + g][0])
        }
      }
    } else {
      const getterOptions = [coldToGoCupShareFeeDiscount,
        coldToGoDepositCupFeeDiscount, coldThermosFeeDiscount, coldPlasticFeeDiscount,
        coldPaperFeeDiscount, coldCompostableFeeDiscount, coldStyrofoamFeeDiscount
      ]
      for (let g=0; g<7; g++) {
        if (coldToGoCupAvailabilityGetters[g] === 'Yes') {
          fDGetters.push(getterOptions[g])
        }
      }
    }
    return fDGetters
  }

  const coldFeeDiscountSetters = () => {
    const fDSetters = []
    if (['Take out', 'Delivery'].indexOf(orderType) === -1) {
      for (let g=0; g<6; g++) {
        if (coldCupAvailabilityGetters[g] === 'Yes') {
          fDSetters.push(stateHooks[coldReusableFDIndex + g][1])
        }
      }
    } else {
      const setterOptions = [setColdToGoCupShareFeeDiscount, setColdToGoDepositCupFeeDiscount,
        setColdThermosFeeDiscount, setColdPlasticFeeDiscount, setColdPaperFeeDiscount,
        setColdCompostableFeeDiscount, setColdStyrofoamFeeDiscount
      ]
      for (let g=0; g<7; g++) {
        if (coldToGoCupAvailabilityGetters[g] === 'Yes') {
          fDSetters.push(setterOptions[g])
        }
      }
    }
    return fDSetters
  }

  const [currUserBadges, setCurrUserBadges] = useState([])
  const [reviewCount, setReviewCount] = useState(0)
  useEffect(async () => {
    await fetch(`${url}user/getUserBadges/${props.userID}`)
    .then(res => res.json())
    .then(data => {
      if (Array.isArray(data.badges)) {
        setCurrUserBadges(data.badges)
      }
      setReviewCount(data.reviewCount)
    }).catch(err => {
      console.log('Error getting user badges\n\n', err);
    })
  }, [])

  const pickNewBadge = () => {
    if (currUserBadges.indexOf('firstReview') < 0) {
      props.updateAppState({level: 1})
      return 'firstReview'
    } else if ((reviewCount >= 4) && (props.level === 1)) {
      props.updateAppState({level: 2})
      return 'level3'
    } else if ((currUserBadges.indexOf('mugLife') < 0 ) && (hotCupType === 'Reusable')){
      return 'mugLife'
    } else if ((currUserBadges.indexOf('coldCup') < 0) && (coldCupType === 'Reusable')) {
      return 'coldCup'
    } else if ((currUserBadges.indexOf('waterCup') < 0) && (waterCupType === 'Reusable')) {
      return 'waterCup'
    } else if ((currUserBadges.indexOf('thermos') < 0 ) && ((hotCupType === 'Thermos') || (coldCupType === 'Thermos'))){
      return 'thermos'
    } else if ((currUserBadges.indexOf('deposit') < 0 ) && ((hotCupType === 'Deposit') || (coldCupType === 'Deposit'))){
      return 'deposit'
    } else if ((currUserBadges.indexOf('compost') < 0 ) && (composting === 'Yes')){
      return 'composting'
    } else {
      return 'noBadge'
    }
  }

  const createReview = () => {
    const reviewObj = {name: props.venue.name, address: props.venue.address,
      neighborhood: props.venue.neighborhood, country: props.venue.country,
      foursquareID: props.venue.foursquareID, authToken: props.authToken,
      longitude: props.venue.longitude, latitude: props.venue.latitude,
      userID: props.userID, timestamp: Date.now()};
    for (let i=0; i<REVIEW_KEYS.length; i++) {
      if (stateHooks[i][0] !== '') {
        reviewObj[REVIEW_KEYS[i]] = stateHooks[i][0];
      }
    }
    return reviewObj;
  }

  const handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }
    if (!props.authToken) {
      alert('Please log in to submit a review.')
    } else if (stateHooks.every(hook => hook[0] === '')) {
      alert('Please enter your observations about how your order was served before submitting a review.')
    } else {
      const review = createReview()
      const badge = pickNewBadge()
      review['badge'] = badge
      fetch(`${url}review/cafe`, {body: JSON.stringify(review),
        method: 'POST', headers: {'Content-Type': 'application/json'}}
      ).then(res => {
        props.toggleBallot()
        props.handleThankYouModal(badge)
        let modalThankYou = document.getElementById('modalThankYou')
        modalThankYou.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }).catch(err => console.log('Hit this error submitting review: ', err));
      if (!props.isReviewer) {
        props.updateAppState({isReviewer: true})
      }
      props.deselectVenueHandler()
    }
  }

  const handleResetBallot = e => {
    e.preventDefault();
    stateHooks.forEach(hook => {  // reset each setter to '' if needed
      if (hook[0]) {
        hook[1]('');  // index 1 contains the setter
      }
    })
    setBookmark('orderTypeSection')
  }

  const backHandler = e => {
    e.preventDefault();
    setLastBackTime(Date.now())
    for (let i=stateHooks.length - 1; i>=0; i--) {
      if (stateHooks[i][0] !== ''){
        if (i === cutleryTypeIndex) {
          stateHooks[i][1]('')
          setBookmark('plateCutlerySection')
          break
        } else if (i === waterStrawIndex) {
          stateHooks[i][1]('')
          setBookmark('tapWaterSection')
          break
        } else if (i === coldStrawTypeIndex) {
          stateHooks[i][1]('')
          setBookmark('coldCupSection')
          break
        } else if (i >= coldCupShareFDIndex && i <= coldLastFDIndex) {  // "cold fee/discount" questions
          for (let j=coldCupShareFDIndex; j<=coldLastFDIndex; j++) {
            stateHooks[j][1]('')
          }
          break
        } else if (i === coldCupTypeIndex && coldFeeDiscountGetters().some(item => !item)) {
          stateHooks[i][1]('')
          for (let j=coldCupShareIndex; j<=coldLastIndex; j++) {
            stateHooks[j][1]('')
          }
          break
        } else if (i >= coldCupShareIndex && i <= coldLastIndex) {  // "is cold ___ used" questions
          for (let j=coldCupShareIndex; j<=coldLastIndex; j++) {
            stateHooks[j][1]('')
          }
          break
        } else if (i === stirrerTypeIndex) {
          stateHooks[i][1]('')
          setBookmark('hotCupSection')
          break
        } else if (i >= hotCupShareFDIndex && i <= hotLastFDIndex) {  // "hot fee/discount" questions
          for (let j=hotCupShareFDIndex; j<=hotLastFDIndex; j++) {
            stateHooks[j][1]('')
          }
          break
        } else if (i === hotCupTypeIndex && hotFeeDiscountGetters().some(item => !item)) {
          stateHooks[i][1]('')
          for (let j=hotCupShareIndex; j<=hotLastIndex; j++) {
            stateHooks[j][1]('')
          }
          break
        } else if (i >= hotCupShareIndex && i <= hotLastIndex) {  // "is hot ___ used" questions
          for (let j=hotCupShareIndex; j<=hotLastIndex; j++) {
            stateHooks[j][1]('')
          }
          break
        } else if (i >= firstGotQuestionIndex && i <= lastGotQuestionIndex) {  // "got..." questions
          for (let j=firstGotQuestionIndex; j<=lastGotQuestionIndex; j++) {
            stateHooks[j][1]('')
          }
          setBookmark('orderTypeSection')
          break
        } else if (i < firstGotQuestionIndex && i >= firstSeatingIndex) {
          for (let j=firstSeatingIndex; j<firstGotQuestionIndex; j++) {
            stateHooks[j][1]('')
          }
          break
        } else {
          stateHooks[i][1]('')
          break
        }
      }
    }
  }

  const [bookmark, setBookmark] = useState('orderTypeSection');
  const [lastBackTime, setLastBackTime] = useState(0);
  const [params, setParams] = useState(PARAMS[props.language]);
  const _values = PARAMS['values']

  const hRowLabels = () => {
    if (props.language === 'en') {
      if (['Take out', 'Delivery'].indexOf(orderType) > -1) {
        return HOT_TO_GO_CUP_ROW_LABELS
      } else {
        return HOT_CUP_ROW_LABELS
      }
    } else {
      if (['Take out', 'Delivery'].indexOf(orderType) > -1) {
        return HOT_TO_GO_CUP_ROW_LABELS_FR
      } else {
        return HOT_CUP_ROW_LABELS_FR
      }
    }
  }

  const cRowLabels = () => {
    if (props.language === 'en') {
      if (['Take out', 'Delivery'].indexOf(orderType) > -1) {
        return COLD_TO_GO_CUP_ROW_LABELS
      } else {
        return COLD_CUP_ROW_LABELS
      }
    } else {
      if (['Take out', 'Delivery'].indexOf(orderType) > -1) {
        return COLD_TO_GO_CUP_ROW_LABELS_FR
      } else {
        return COLD_CUP_ROW_LABELS_FR
      }
    }
  }

  const frOrderTypePrefix = () => {
    let _key = _values.orderType.values.indexOf(orderType)
    let prompts = ['Pour les commandes sur place',
    'Pour les commandes à emporter', 'Pour les livraisons']
    return prompts[_key]
  }

  const nextSection = () => {
    const sections = ['orderTypeSection',  // default section
      gotHotDrink === 'Hot drink' ? 'hotCupSection' : false,
      gotColdDrink === 'Cold drink' ? 'coldCupSection' : false,
      gotTapWater === 'Tap water' ? 'tapWaterSection' : false,
      gotFood === 'Food' ? 'plateCutlerySection' : false,
      'wasteSection'  // this is another default section
    ].filter(Boolean);
    const currentIndex = sections.indexOf(bookmark)
    if (currentIndex < sections.length - 1) {
      setBookmark(sections[currentIndex + 1])
    }  // else don't do anything
  }

  // TODO: don't ask about a straw if someone used a cold thermos
  return (
    <div className="cafeBallotContainer">
      {bookmark === 'orderTypeSection' &&
      <div className='ballotSection orderTypeSection fadeIn'>
        <h2>{props.language === 'en' ? 'Order Type' : 'Type de commande'}
          <span role="img" aria-label="Writing hand">✍️</span></h2>
        {orderType === '' &&
        <QuestionCard language={props.language} {..._values["orderType"]} {...params["orderType"]}
          getter={orderType} setter={setOrderType} backHandler={backHandler}
        />}
        {((orderType && orderType !== 'Delivery') && [indoorSeating, outdoorSeating].some(answer => answer === '')) &&
        <GridFormComponent language={props.language} {..._values["seating"]} {...params["seating"]}
          getters={[indoorSeating, outdoorSeating]}
          setters={[setIndoorSeating, setOutdoorSeating]}
          backHandler={backHandler}
        />}
        {((orderType === 'Delivery') || [indoorSeating, outdoorSeating].every(answer => answer)) &&
        <QuestionCard language={props.language} {..._values["gotHotDrink"]} {...params["gotHotDrink"]}
          getters={[gotHotDrink, gotColdDrink, gotTapWater, gotFood]}
          setters={[setGotHotDrink, setGotColdDrink, setGotTapWater, setGotFood]}
          backHandler={backHandler} extraHandler={nextSection}
        />}
      </div>}
      {bookmark === 'hotCupSection' &&
      <div className='ballotSection hotDrinkSection fadeIn'>
        <h2>{props.language === 'en' ? 'Hot Drink' : 'Boisson chaude'} <span role="img" aria-label="Coffee in mug">☕</span></h2>
        {(hCAG().some(item => item === '') || !hCAG().some(item => item === 'Yes')) &&
        <GridFormComponent language={props.language} backHandler={backHandler} prompt={props.language === 'en' ?
            `${orderType} hot drinks are served in which types of cups? Please indicate "Yes" for at least one.`
            : `${frOrderTypePrefix()}, les boissons chaudes sont servies dans 
            quel type de tasse? Veuillez cocher au moins un "oui".`}
          rows={hRowLabels()} getters={hCAG()} setters={hCAS()}
          {..._values["isHotXCupUsed"]} {...params["isHotXCupUsed"]} 
        />}
        {hCAG().every(item => item) && hCAG().some(item => item === 'Yes')
          && hotFeeDiscountGetters().some(item => item === '') &&
        <GridFormComponent language={props.language} rows={hotFeeDiscountRows()}
          getters={hotFeeDiscountGetters()} setters={hotFeeDiscountSetters()}
          {..._values["hotReusableFeeDiscount"]}
          {...params["hotReusableFeeDiscount"]} backHandler={backHandler}
        />}
        {hCAG().every(item => item) && hCAG().some(item => item === 'Yes')
          && hotFeeDiscountGetters().every(item => item)
          && hotFeeDiscountGetters().some(item => ['Fee', 'Disc.'].indexOf(item) > -1 )
          && hotCupPromotion === '' &&
        <QuestionCard language={props.language} getter={hotCupPromotion} setter={setHotCupPromotion}
          {..._values["hotCupPromotion"]} {...params["hotCupPromotion"]} backHandler={backHandler}
        />}
        {(hotCupPromotion || (hCAG().every(item => item) && hCAG().some(item => item === 'Yes')
          && hotFeeDiscountGetters().every(item => ['No/Unsure', 'Skipped'].indexOf(item) > -1)))
          && hotCupType === '' &&
        <QuestionCard language={props.language} {..._values["hotCupType"]} {...params["hotCupType"]}
          values={['Take out', 'Delivery'].indexOf(orderType) > -1 ? HOT_TO_GO_CUP_ROW_VALUES : HOT_CUP_ROW_VALUES}
          choices={hRowLabels()} backHandler={backHandler} getter={hotCupType} 
          setter={setHotCupType}
        />}
        {(hotCupPromotion || (hCAG().every(item => item) && hCAG().some(item => item === 'Yes')
          && hotFeeDiscountGetters().every(item => ['No/Unsure', 'Skipped'].indexOf(item) > -1)))
          && hotCupType && hotCupDefault === '' &&
        <QuestionCard language={props.language} getter={hotCupDefault} setter={setHotCupDefault}
          prompt={props.language === 'en' ? `For ${orderType.toLowerCase()} hot drinks, is a ${hotCupType.toLowerCase()}
            ${['Cup with deposit', 'Thermos'].indexOf(hotCupType) > -1 ? '' : ' cup'} the default?`
            : `${frOrderTypePrefix()} de boissons chaudes, est-ce que votre type 
            de tasse est utilisé par défaut?`}
          backHandler={backHandler} values={[hotCupType, 'They asked', 'I asked', 'Unsure']}
          {..._values["hotCupDefault"]} {...params["hotCupDefault"]}
        />}
        {(hotCupType === 'Thermos' && hotCupDefault) && hotThermosFill === '' &&
        <QuestionCard language={props.language} getter={hotThermosFill} setter={setHotThermosFill}
          {..._values["hotThermosFill"]} {...params["hotThermosFill"]} backHandler={backHandler}
        />}
        {['Paper', 'Compostable', 'Styrofoam'].indexOf(hotCupType) > -1
          && hotCupDefault && hotDoubleCup === '' &&
        <QuestionCard language={props.language} {..._values["hotDoubleCup"]} {...params["hotDoubleCup"]}
          getter={hotDoubleCup} setter={setHotDoubleCup} backHandler={backHandler}
        />}
        {hotDoubleCup && hotLidType === '' &&
        <QuestionCard language={props.language} {..._values["hotLidType"]} {...params["hotLidType"]}
          getter={hotLidType} setter={setHotLidType} backHandler={backHandler}
        />}
        {hotLidType && hotSleevePolicy === '' &&
        <QuestionCard language={props.language} {..._values["hotSleevePolicy"]} {...params["hotSleevePolicy"]}
          getter={hotSleevePolicy} setter={setHotSleevePolicy} backHandler={backHandler}
        />}
        {hotSleevePolicy === 'Default on' && hotSleeveType === '' &&
        <QuestionCard language={props.language} {..._values["hotSleeveType"]} {...params["hotSleeveType"]}
          getter={hotSleeveType} setter={setHotSleeveType} backHandler={backHandler}
        />}
        {((['On request', 'Skipped'].indexOf(hotSleevePolicy) > -1 || hotSleeveType) || hotThermosFill
          || ((['Reusable', 'Cup with deposit', 'Cup-share program'].indexOf(hotCupType) > -1) && hotCupDefault))
          && stirrerType === '' &&
        <QuestionCard language={props.language} {..._values["stirrerType"]} {...params["stirrerType"]} getter={stirrerType}
          setter={setStirrerType} extraHandler={nextSection} backHandler={backHandler}
        />}
      </div>}
      {bookmark === 'coldCupSection' &&
      <div className='ballotSection coldDrinkSection fadeIn'>
        <h2>{props.language === 'en' ? 'Cold Drink' : 'Boisson Froide'} <span role="img" aria-label="Ice cube">🧊</span></h2>
        {(cCAG().some(item => item === '') || !cCAG().some(item => item === 'Yes')) &&
        <GridFormComponent language={props.language} prompt={props.language === 'en' ? `${orderType} 
          cold drinks are served in which types of cups? Please indicate "Yes" for at least one.`
          : `${frOrderTypePrefix()} de boissons froides, est-ce que votre type 
          de tasse est utilisé par défaut?`}
          rows={cRowLabels()} getters={cCAG()} setters={cCAS()} backHandler={backHandler}
          {..._values["isColdReusableUsed"]} {...params["isColdReusableUsed"]}
        />}
        {cCAG().every(item => item) && cCAG().some(item => item === 'Yes')
          && coldFeeDiscountGetters().some(item => item === '') &&
        <GridFormComponent language={props.language} rows={coldFeeDiscountRows()}
          getters={coldFeeDiscountGetters()} setters={coldFeeDiscountSetters()} 
          {..._values["coldReusableFeeDiscount"]}
          {...params["coldReusableFeeDiscount"]} backHandler={backHandler}
        />}
        {cCAG().every(item => item) && cCAG().some(item => item === 'Yes')
          && coldFeeDiscountGetters().every(item => item)
          && coldFeeDiscountGetters().some(item => ['Fee', 'Disc.'].indexOf(item) > -1 )
          && coldCupPromotion === '' &&
        <QuestionCard language={props.language} getter={coldCupPromotion} setter={setColdCupPromotion}
          {..._values["coldCupPromotion"]} {...params["coldCupPromotion"]} 
          backHandler={backHandler}
        />}
        {(coldCupPromotion || (cCAG().every(item => item) && cCAG().some(item => item === 'Yes')
          && coldFeeDiscountGetters().every(item => ['No/Unsure', 'Skipped'].indexOf(item) > -1)))
          && coldCupType === '' &&
        <QuestionCard language={props.language} getter={coldCupType} setter={setColdCupType} {..._values["coldCupType"]}
          {...params["coldCupType"]} backHandler={backHandler} choices={cRowLabels()}
          values={['Take out', 'Delivery'].indexOf(orderType) > -1 ? COLD_TO_GO_CUP_ROW_VALUES : COLD_CUP_ROW_VALUES}
        />}
        {(coldCupPromotion || (cCAG().every(item => item) && cCAG().some(item => item === 'Yes')
          && coldFeeDiscountGetters().every(item => ['No/Unsure', 'Skipped'].indexOf(item) > -1)))
          && coldCupType && coldCupDefault === '' &&
        <QuestionCard language={props.language} getter={coldCupDefault} setter={setColdCupDefault}
          prompt={props.language === 'en' ? `For ${orderType.toLowerCase()} cold 
            drinks, is a ${coldCupType.toLowerCase()} ${['Cup with deposit',
            'Thermos'].indexOf(coldCupType) > -1 ? '' : ' cup'} 
            the default?` : `${frOrderTypePrefix()} de boissons froides, est-ce 
            que votre type de tasse est utilisé par défault?`}
          values={[coldCupType, 'They asked', 'I asked', 'Unsure']}
          backHandler={backHandler} {...params["coldCupDefault"]}
        />}
        {(coldCupType === 'Thermos' && coldCupDefault) && coldThermosFill === '' &&
        <QuestionCard language={props.language} getter={coldThermosFill} setter={setColdThermosFill}
        {..._values["coldThermosFill"]} {...params["coldThermosFill"]}
        backHandler={backHandler}
        />}
        {['Plastic', 'Paper', 'Compostable', 'Styrofoam'].indexOf(coldCupType) > -1
          && coldCupDefault && coldLidType === '' &&
        <QuestionCard language={props.language} {..._values["coldLidType"]} {...params["coldLidType"]}
          getter={coldLidType} setter={setColdLidType} backHandler={backHandler}
        />}
        {coldLidType && coldSleevePolicy === '' &&
        <QuestionCard language={props.language} getter={coldSleevePolicy} setter={setColdSleevePolicy}
          {..._values["coldSleevePolicy"]} {...params["coldSleevePolicy"]}
          backHandler={backHandler}
        />}
        {(coldSleevePolicy === 'Default on' || coldSleevePolicy === 'Skipped')
          && coldSleeveType === '' &&
        <QuestionCard language={props.language} getter={coldSleeveType} setter={setColdSleeveType}
          {..._values["coldSleeveType"]} {...params["coldSleeveType"]}
          backHandler={backHandler}
        />}
        {coldStrawType === '' && (coldSleevePolicy === 'On request' || coldSleeveType || coldThermosFill
          || (['Reusable', 'Cup with deposit', 'Cup-share program'].indexOf(coldCupType) > -1 && coldCupDefault)) &&
        <QuestionCard language={props.language} getter={coldStrawType} setter={setColdStrawType}
          {..._values["coldStrawType"]} {...params["coldStrawType"]}
          backHandler={backHandler} extraHandler={nextSection}
        />}
      </div>}
      {bookmark === 'tapWaterSection' &&
      <div className='ballotSection tapWaterSection fadeIn' id='tapWaterSection'>
        <h2>{props.language === 'en' ? 'Tap Water' : 'Eau du robinet'} 
          <span role="img" aria-label="Faucet and cup">🚰</span></h2>
        {waterCupType === '' &&
        <QuestionCard language={props.language} getter={waterCupType} setter={setWaterCupType}
          {..._values["waterCupType"]} {...params["waterCupType"]}
          backHandler={backHandler}
        />}
        {['', 'Reusable', 'No Tap Water'].indexOf(waterCupType) === -1 && waterLid === '' &&
        <QuestionCard language={props.language} {..._values["waterLid"]} {...params["waterLid"]}
          getter={waterLid} setter={setWaterLid} backHandler={backHandler}
        />}
        {(waterCupType === 'Reusable' || waterLid) &&
        <QuestionCard language={props.language} backHandler={backHandler} getter={waterStraw}
          setter={setWaterStraw} extraHandler={nextSection}
          {..._values["waterStraw"]} {...params["waterStraw"]}
        />}
      </div>}
      {bookmark === 'plateCutlerySection' &&
      <div className='ballotSection plateCutlerySection fadeIn' id='plateCutlerySection'>
        <h2>{props.language === 'en' ? 'Plates & Utensils' : 'Assiettes & couverts'} 
          <span role="img" aria-label="Plate, cutlery"> ⚪🍴</span></h2>
        {plateType === '' && 
        <QuestionCard language={props.language} {..._values["plateType"]} {...params["plateType"]}
          getter={plateType} setter={setPlateType} backHandler={backHandler}
        />}
        {plateType &&
        <QuestionCard language={props.language} getter={cutleryType} setter={setCutleryType}
          {..._values["cutleryType"]} {...params["cutleryType"]}
          extraHandler={nextSection} backHandler={backHandler}
        />}
      </div>}
      {bookmark === 'wasteSection' &&
      <div className='ballotSection wasteSection fadeIn' id='wasteSection'>
        <h2>{props.language === 'en' ? 'Bottles & Waste' : 'Bouteilles & Déchets'} 
          <span role="img" aria-label="Recycling symbol">♻</span>
            <span role="img" aria-label="Seedling"> 🌱</span></h2>
        {hasPlasticBottles === '' &&
        <QuestionCard language={props.language} getter={hasPlasticBottles} setter={setHasPlasticBottles}
          {..._values["hasPlasticBottles"]} {...params["hasPlasticBottles"]}
          backHandler={backHandler}
        />}
        {hasPlasticBottles && recycling === '' && 
        <QuestionCard language={props.language} {..._values["recycling"]} {...params["recycling"]}
          getter={recycling} setter={setRecycling} backHandler={backHandler}
        />}
        {recycling && composting === '' &&
        <QuestionCard language={props.language} {..._values["composting"]} {...params["composting"]}
          getter={composting} setter={setComposting} backHandler={backHandler}
          extraHandler={handleSubmit}
        />}
        {composting &&
        <h3 id="submittingBallot">
          {props.language === 'en' ? 'Auto-submitting...' : 'Soumission automatique...'}</h3>}
      </div>}
      <>
        <div className='ballot-ctrl-container'>
          <button className='cafeBallotBtn' onClick={handleSubmit}
            title={props.language === 'en' ? 'Submit review' : 'Soumettre éval.'}
            id='submitReview'>{props.language === 'en' ? 'Submit Anytime' : 'Soumettre'}</button>
          <button className='cafeBallotBtn' onClick={handleResetBallot}
            title={props.language === 'en' ? 'Reset all answers'
            : 'Réinitialiser toutes les réponses'} id='resetBallot'>Reset</button>
        </div>
      </>
    </div>
  )
}