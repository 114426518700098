import React, {useState, useEffect} from 'react'
import useKeyboard from './hooks/useKeyboard'
const url = process.env.NODE_ENV === 'production' ? 'https://ecorate.eco/' : 'http://localhost:5000/'

export const AddVenue = props => {
  const [venueCategory, setVenueCategory] = useState('')
  const [venueName, setVenueName] = useState('')
  const [isBlocked, setIsBlocked] = useState(false)
  const manageKeyboard = useKeyboard()

  const submitMissingVenue = e => {
    e.preventDefault()
    if (!(Boolean(props.userID)) || (venueCategory == '') || (venueName == '')) {
      setIsBlocked(true)
      return
    }
    const missingVenue = {...props.venue, adding: false, category: venueCategory, name: venueName.trim(), isCafeType: true}
    props.updateAppState({venue: missingVenue})
    fetch(`${url}mapping/newCafe`, {body: JSON.stringify(missingVenue),
      method: 'POST', headers: {'Content-Type': 'application/json'}}
    ).then(res => {
      props.resizeMap()
    }).catch(err => {
      console.log('Error submitting missingVenue form:\n', err)
    })
  }

  useEffect(() => {
    if (props.platform !== 'web') {
      manageKeyboard('missingVenueName', props.resizeMap)
    }
  }, [])

  return(
    <form className='missingVenueForm'>
      <select required onChange={e => setVenueCategory(e.target.value)}>
        <option value=''>--{props.language === 'fr' ? `Type d'établissement` : 'Venue type'}--</option>
        <option >Cafe</option>
        <option >Bakery</option>
        <option >Restaurant</option>
        <option >Refillery</option>
        <option >Other</option>
      </select>
      <label for='missingVenueName'>{props.language === 'fr' ? `Nom d'établissement` : 'Venue name'}:</label>
      <input type='text' id='missingVenueName' required minLength={1} maxLength={100} size={30}
        value={venueName} onChange={e => setVenueName(e.target.value)}/>
      <p className={`display-${isBlocked && !(Boolean(props.userID))} failure`}>Please log in to add a missing venue</p>
      <p className={`display-${isBlocked && (venueCategory == '')} failure`}>Please enter the venue category</p>
      <p className={`display-${isBlocked && (venueName == '')} failure`}>Please enter the venue name</p>
      <button id='submitVenueBtn' className='reviewButton' onClick={submitMissingVenue}>
        {props.language === 'fr' ? 'Ajouter' : 'Add venue'}
      </button>
    </form>
  )
}
