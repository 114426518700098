import React, {useState, useEffect} from 'react';
const texts = require('./constants/hours.json')


export const Hours = ({hours, language}) => {
  /* Component used for showing a venue's hours. The hours are encoded as a
  [String] where Monday's opening and closing times are in positions 0 and 1,
  Tuesdys in 2 and 3, etc. Hours are encoded with a 24hr clock, and minutes are 
  after the decimal point, so "13.45" is 1:45pm. "C" is used at an opening time 
  to show that the place is closed all day that day of the week.*/
  const [weekday, setWeekday] = useState(0)
  const [showAll, setShowAll] = useState(false)
  const [userTime, setUserTime] = useState(0)
  const _texts = texts[language]

  const _24to12 = (hour) => {
    if ((Math.floor(hour) === 24) || (Math.floor(hour) === 12)) {
      return hour % 24
    } else {
      return hour % 12
    }
  }

  const toggleDayWeek = () => {
    setShowAll(!showAll)
    props.resizeMap()
  }

  useEffect(() => {
    const _date = new Date()
    setWeekday((_date.getDay() + 6) % 7)  // Monday is 0
    setUserTime(_date.getHours() + (_date.getMinutes() / 100))
  }, [])

  const post = []
  const hNums = hours.split(';')
  for (let i=0; i<14; i+=2) {
    let start = Number(hNums[i])
    let end = Number(hNums[i+1])
    const className = ((weekday === i/2) && ((userTime < start) || (userTime > end))) ? 'closed' : 'open'
    if (start === -1) {  // meaning closed all day
      post.push(
        <div className='closed dayRow' key={`dayKey${i}`}>
          <div className={`${className} day`}>{_texts.dayNames[i/2]}&nbsp;</div>
          <div className={className}>{_texts.closed}</div>
        </div>
      )
      continue
    }
    const startSuffix = start < 12 ? _texts['am'] : _texts['pm']
    const endSuffix = end < 12 ? _texts['am'] : _texts['pm']
    if (language === 'en') {
      start = _24to12(start)
      end = _24to12(end)
    }
    const sFloor = Math.floor(start)
    const startMinutes = Math.round((start - sFloor) * 100)
    const startTime = String(sFloor) + (startMinutes ? `:${startMinutes}` : '') + startSuffix
    const eFloor = Math.floor(end)
    const endMinutes = Math.round((end - eFloor) * 100)
    const endTime = String(eFloor) + (endMinutes ? `:${endMinutes}` : '') + endSuffix
    post.push(
      <div className={`${className} dayRow`} key={`dayKey${i}`}>
        <div className={`${className} day`}>{_texts.dayNames[i/2]}&nbsp;</div>
        <div className={className}>{startTime + ' - ' + endTime}</div>
      </div>
    )
  }

  return (
    <div className='hourContainer' onClick={e => setShowAll(!showAll)} style={{width: '100%', display: 'flex'}}>
      Hours: {showAll ? '^' : '˅'}
      <div className='hours'>
        {showAll ? post : post[weekday]}
      </div>
    </div>
  )
}
